<template>
    <div>
        <loading-circle v-if="loading"></loading-circle>

        <commentbox-input v-if="!loading" :postid="postid" @comment_created="load_comments" :layer=1></commentbox-input>

        <div v-for="comment in comments" :key="comment.ID" class="comment-container">
            <commentbox-comment v-if="!loading" :postid="postid" :comment="comment" :layer=1 :adminmode="adminmode" @comment_removed="load_comments"></commentbox-comment>
        </div>
    </div>
</template>

<script>
    import commentbox_comment from "./commentbox-comment";
    import commentbox_input from "./commentbox-input";


    export default {
        components: {
            "commentbox-comment": commentbox_comment,
            "commentbox-input"  : commentbox_input,
        },
        props     : ["postid", "adminmode"],
        data() {
            return {
                comments: [],
                loading : false,
            }
        },
        watch     : {
            postid() {
                if (this.postid)
                    this.load_comments();
            },
        },
        created() {
            if (this.postid)
                this.load_comments();
        },
        methods   : {
            async load_comments() {
                this.loading  = true;
                this.comments = [];
                let response  = await this.func.ax("/php/post_comment.php", {
                    func  : "get_comments",
                    postid: this.postid,
                });
                if (this.func.isObject(response))
                    this.comments = response;
                else
                    this.eventbus.$emit("SB", response, "error");
                this.loading = false;
            },

        }
    }
</script>
