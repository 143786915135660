<template>
    <v-dialog v-model="dialog" width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="red">
                mdi-flag
            </v-icon>
        </template>
        <v-form v-model="valid" ref="r_form_report" class="px-4 grey darken-3">
            <dragger></dragger>
            <v-row>
                <v-col cols="12">
                    <span class="font-weight-bold">{{$t('report-comment.you-are-reporting')}}</span>
                </v-col>
                <v-col cols="12">
                    <span class="orange--text font-weight-bold">{{comment.comment}}</span>
                </v-col>
                <v-col cols="12">
                    {{$t('report-comment.comment-id')}} <span class="orange--text">{{comment.ID}}</span>
                </v-col>


                <v-col cols="12">
                    <v-select
                            v-model="reason"
                            :label="$t('report-comment.reason')"
                            :items="$variables.v.comment_report_reasons"
                            item-value="id"
                            :color="$variables.v.theme.secondary"
                            dense
                            single-line
                            outlined
                            @change="reason_changed">
                        <template slot=selection slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                        <template slot=item slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                    </v-select>


                    <v-text-field
                            v-model="custom_reason"
                            v-if="use_custom_reason"
                            :color="$variables.v.theme.secondary"
                            outlined
                            dense
                            counter
                            maxlength="50"
                            :rules="[rules.required, rules.min5, rules.max50]"></v-text-field>
                </v-col>


                <v-col cols="12">
                    <v-row>
                        <v-col cols="6" class="text-center">
                            <v-btn @click="cancel_report"
                                   depressed>
                                {{$t('report-comment.cancel-report')}}
                            </v-btn>
                        </v-col>
                        <v-col cols="6" class="text-center">
                            <v-btn :disabled="!valid || !select_is_filled"
                                   color="red"
                                   depressed
                                   @click="send_report">
                                {{$t('report-comment.send-report')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        props   : ['comment'],
        data() {
            return {
                dialog           : false,
                valid            : false,
                reason           : null,
                use_custom_reason: false,
                custom_reason    : "",

                rules: {
                    required: v => (!!v) || this.$t("rules.required"),
                    min5    : v => (v !== undefined && v.length >= 5) || this.$t("rules.min-5-chars"),
                    max50   : v => (v !== undefined && v.length <= 50) || this.$t("rules.max-50-chars"),
                },
            }
        },
        computed: {
            select_is_filled() {
                return this.reason !== null;
            }
        },
        methods : {
            build_postdata() {
                return {
                    reason   : this.reason === this.$variables.v.post_report_comment_custom_id_reason ? this.custom_reason : this.$variables.v.comment_report_reasons[this.reason - 1].text,
                    postid   : this.comment.postID,
                    commentid: this.comment.ID,
                    userid   : this.$user.user.id,
                    token    : this.$user.user.token,
                };
            },
            async send_report() {
                if (this.$refs.r_form_report.validate()) {
                    let response = await this.func.ax("/php/post_comment_report.php", this.build_postdata());

                    await this.$root.check_response(response, () => {
                        this.eventbus.$emit("SB", "report-comment.msg.success-report-comment", "success", 5);
                        this.$refs.r_form_report.reset();
                        this.dialog = false;
                        this.$emit("reported");
                    }, "report-comment.msg.error-report-comment");
                    if (response === "php.already-reported")
                        this.dialog = false;
                }
            },
            reason_changed() {
                this.use_custom_reason = (this.reason === this.$variables.v.post_report_comment_custom_id_reason);
            },
            cancel_report() {
                this.dialog = false;
                this.$refs.r_form_report.reset()
            }
        },
    }
</script>