<template>
    <div class="float-right">
        <dialog-confirm ref="confirm"></dialog-confirm>
        <v-tooltip bottom v-if="$user.user.id === comment.userID ||
                                            $root.check_mod()">
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                        v-if="comment.disabled ==='0'"
                        v-bind="attrs"
                        v-on="on"
                        @click="delete_comment"
                        color="red">
                    mdi-trash-can-outline
                </v-icon>
            </template>
            <span>{{$t("dialog-post.delete-own-post")}}</span>
        </v-tooltip>
    </div>
</template>

<script>
    export default {
        props  : ["comment"],
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            async delete_comment() {
                if (await this.$refs.confirm.open(this.$t("dialog-post.dialog-confirm.delete-comment"), this.$t("dialog-post.dialog-confirm.are-you-sure"))) {
                    if (this.$user.user.id === this.comment.userID || this.$root.check_mod()) {
                        let response = await this.func.ax("/php/post_comment_delete.php", {
                            commentid: this.comment.ID,
                            userid   : this.$user.user.id,
                            token    : this.$user.user.token,
                        });
                        await this.$root.check_response(response, () => {
                            this.$emit("comment_removed");
                            this.eventbus.$emit("SB", "dialog-post.msg.comment-is-deleted", "success", 5);
                        }, "dialog-post.msg.comment-remove-failed");

                        this.dialog = false;
                    }
                }
            },
        }
    }
</script>