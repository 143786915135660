<template>
    <div>
        <img :src="post.picture_url"
             @load="valid_picture = true"
             @error="valid_picture = false"
             height="0"
             alt=""
             loading="lazy">

        <v-dialog :key="key_dialog_post" v-model="dialog" :width="valid_picture ? '1000px' : '600px'">
            <v-card flat>
                <v-layout>
                    <div class="d-md-none mt-4 ml-4">
                        <v-icon @click="dialog = false"
                                color="red">mdi-close
                        </v-icon>
                    </div>

                    <div class="post-options-container mt-4">
                        <dialog-confirm ref="confirm"></dialog-confirm>


                        <div v-if="($user.user.id === post.userID || $root.check_mod()) && post.disabled === '0' && post.marked_as_expired === '0'"
                             class="mr-4">
                            <post-mark-expired :post="post" @post_marked_as_expired="post_marked_as_expired"></post-mark-expired>
                        </div>

                        <div v-if="($user.user.id === post.userID || $root.check_mod()) && post.disabled === '0'"
                             class="mr-4">
                            <post-remove :post="post" @post_removed="post_removed"></post-remove>
                        </div>

                        <div v-if="post.disabled === '0'
                                            && $user.user.auth
                                            && $user.user.roleID !== $variables.v.role_ids.leecher
                                            && post.reported === 'false'"
                             class="mr-4">
                            <dialog-post-report @reported="post.reported = true"
                                                :post="post"></dialog-post-report>
                        </div>

                        <div v-if="($user.user.id === post.userID || $root.check_mod()) && post.disabled === '0' && post.marked_as_expired === '0'"
                             class="mr-4">
                            <dialog-post-edit :postid="post.ID" @post_changed="post_changed"></dialog-post-edit>
                        </div>
                    </div>
                </v-layout>

                <div class="mx-3">
                    <v-row>
                        <!--Picture -->
                        <v-col v-show="valid_picture" :md="valid_picture ? 6 : 12">
                            <div class="pa-1" style="position:relative">
                                <img :src="c_valid_picture"
                                     class="post-image"
                                     style="max-height:400px"
                                     alt=""
                                     loading="lazy">
                                <div v-if="post.deal_discount_in_percent" class="deal-banner">
                                    {{post.deal_discount_in_percent}} %
                                </div>
                            </div>
                        </v-col>

                        <!--Informations -->
                        <v-col cols="12" :md="valid_picture ? 6 : 12">
                            <v-row>
                                <v-col cols="12">
                                    <v-card-title style="word-break:break-word" :class="{'error--text': post.disabled ==='1'}">{{post.title}}
                                    </v-card-title>
                                </v-col>
                            </v-row>

                            <div class="disable-select">
                                <v-row>
                                    <v-col cols="12">
                                        <post-tags :post="post"></post-tags>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="8">
                                        <div class="my-3">{{$t("dialog-post.created-by")}}</div>
                                        <miniview-user class="ml-3" :user="post"></miniview-user>
                                    </v-col>

                                    <v-col cols="4" class="text-right">
                                        <div class="my-3" @click="copy_share_URL">
                                            <span class="mr-2">{{$t("dialog-post.share")}}</span>
                                            <v-icon>mdi-share-all-outline</v-icon>
                                        </div>
                                    </v-col>
                                </v-row>


                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <post-voting :post="post" show_points="true" @voted="voted"></post-voting>
                                    </v-col>

                                    <v-col cols="12" sm="6" v-if="post.expiration_date">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                        <span class="float-right"
                                              v-bind="attrs"
                                              v-on="on">
                                            <v-icon class="mr-2">mdi-clock-outline</v-icon>{{expiration}}
                                        </span>
                                            </template>
                                            <span>{{$t("dialog-post.estimated-expiration")}}</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>


                                <!--Deal prices-->
                                <v-row v-if="Number(post.offer_type) === $variables.v.offer_types.deal"
                                       align="center"
                                       justify="center">
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="5" class="text-right">
                                                <del>{{post.deal_price_original}} <span v-if="post.deal_price_original">{{post.deal_currency}}</span>
                                                </del>
                                            </v-col>
                                            <v-col cols="2" class="text-center">
                                                <v-icon>mdi-arrow-right-bold</v-icon>
                                            </v-col>
                                            <v-col cols="5" class="text-left font-weight-bold red--text accent-1">
                                                {{post.deal_price_reduced}} <span v-if="post.deal_price_original">{{post.deal_currency}}</span>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>


                                <!--Link Buttons-->
                                <v-row>
                                    <v-col cols="12" sm="6" v-if="post.base_game_url">
                                        <v-row justify="center">
                                            <template>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn :color="$variables.v.theme.primary"
                                                               depressed
                                                               v-bind="attrs"
                                                               v-on="on"
                                                               @click="open_baseproduct">
                                                            {{$t("dialog-post.open-basegame")}}
                                                        </v-btn>
                                                    </template>
                                                    <span>{{post.base_game_url}}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" :sm="post.base_game_url ? 6 : 12">
                                        <v-row justify="center">
                                            <template>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-if="post.offer_url.trim()"
                                                               :color="$variables.v.theme.primary"
                                                               depressed
                                                               v-bind="attrs"
                                                               v-on="on"
                                                               @click="open_offer">
                                                            {{Number(post.offer_type) === $variables.v.offer_types.giveaway ?
                                                            $t("dialog-post.open-giveaway") :
                                                            $t("dialog-post.open-deal")}}
                                                        </v-btn>
                                                    </template>
                                                    <span>{{post.offer_url}}</span>
                                                </v-tooltip>
                                            </template>
                                        </v-row>
                                    </v-col>
                                </v-row>

                                <v-row class="mt-3">
                                    <v-card-text>
                                        <v-tooltip v-if="$user.user.auth === true
                                                        && post.clicked_expired === 'false'
                                                        && post.marked_as_expired === '0'
                                                        && post.disabled === '0'"
                                                   bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs"
                                                        v-on="on"
                                                        class="mr-2"
                                                        @click="say_expired()">
                                                    mdi-clock-time-eleven-outline
                                                </v-icon>
                                            </template>
                                            <span>{{$t("dialog-post.click-if-expired")}}</span>
                                        </v-tooltip>

                                        <span>
                                        <span class="font-weight-bold"
                                              :class="{'red--text':post.count_expired > $variables.v.post_expiration_max_bad,
                                                         'yellow--text':post.count_expired <= $variables.v.post_expiration_max_bad && post.count_expired > $variables.v.post_expiration_max_good,
                                                         'green--text':post.count_expired <= $variables.v.post_expiration_max_good}">
                                        {{post.count_expired}}
                                        </span>
                                    {{$t("dialog-post.said-expired")}}</span>
                                    </v-card-text>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>

                    <!--Description-->
                    <v-row>
                        <v-card-text class="content-from-textarea">
                            <read-more :text="post.description"
                                       :more-str="$t('read-more.read-more')"
                                       :less-str="$t('read-more.read-less')"
                                       link="#"
                                       :max-chars="200"></read-more>
                        </v-card-text>
                    </v-row>


                    <!--Commentbox-->
                    <v-row>
                        <v-card-text>
                            <commentbox :postid="post.ID" :adminmode="adminmode"></commentbox>
                        </v-card-text>
                    </v-row>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import commentbox from "../../post-page/post/commentbox/commentbox";
    import dialog_post_edit from "./dialog-post-edit";
    import dialog_post_report from "./dialog-post-report";
    import post_mark_expired from "../../post-page/post-mark-expired";
    import post_remove from "../../post-page/post/post-remove";


    export default {
        components: {
            "commentbox"        : commentbox,
            "dialog-post-edit"  : dialog_post_edit,
            "dialog-post-report": dialog_post_report,
            "post-mark-expired" : post_mark_expired,
            "post-remove"       : post_remove
        },
        props     : ["adminmode"],
        data() {
            return {
                dialog         : false,
                valid_picture  : false,
                post           : {},
                expiration     : "",
                key_dialog_post: 0,
            }
        },
        computed  : {
            c_valid_picture() {
                return this.valid_picture ? this.post.picture_url : this.$variables.v.post_defaut_picture;
            }
        },
        watch     : {
            dialog() {
                if (this.dialog) {
                    // Remove ID and Slashes from Path (this is important when a user goes on the direct link of a post, because than its a route
                    // If he is just clicking a post to open it its just in the history
                    let path = this.$route.path.replace(new RegExp("/+$"), '').replace(new RegExp("/p/[0-9]+$"), '');
                    // Add the new ID to the Path
                    history.pushState(
                        {},
                        null,
                        path + '/p/' + this.post.ID
                    );
                }
                else {
                    // Wait for the post data is replaced
                    setTimeout(() => {
                        this.valid_picture    = false;
                        // Bild url zurücksetzen damit valid_pictures wieder auf false geht
                        this.post.picture_url = "";
                    }, 200);

                    // Remove ID and Slashes from Path (its important for routes)
                    let path = this.$route.path.replace(new RegExp("/+$"), '').replace(new RegExp("/p/[0-9]+$"), '');
                    // However this is important on trending site else its an empty string
                    path     = path === "" ? "/" : path;
                    history.pushState(
                        {},
                        null,
                        path
                    );
                    this.$emit("post_closed", this.post);
                }
            }
        },
        methods   : {
            open_dialog(postid) {
                this.load_post(postid)
            },
            async load_post(postid) {
                let response = await this.func.ax("/php/getter.php", {
                    func     : "get_single_post",
                    userid   : this.$user.user.id,
                    postid   : postid,
                    adminmode: this.adminmode,
                });
                if (response) {
                    this.post = response;
                    this.set_expiration_date();
                    this.key_dialog_post++;

                    this.dialog = true;
                }
                else {
                    this.eventbus.$emit("SB", "dialog-post.msg.post-not-found", "error");
                    this.$emit("post_not_found", postid);
                }
            },
            open_baseproduct() {
                window.open(this.post.base_game_url, "_blank");
            },
            open_offer() {
                window.open(this.post.offer_url, "_blank");
            },
            voted(action, points) {
                this.post.action = action;
                this.post.points = points;
                this.$emit("voted", this.post.ID, action, points);
            },
            async say_expired() {
                let data = {
                    postid: this.post.ID,
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                };

                let response = await this.func.ax("/php/post_expired.php", data);

                await this.$root.check_response(response, () => {
                    this.post.clicked_expired = "true";
                    this.post.count_expired   = parseInt(this.post.count_expired) + 1;
                }, "dialog-post.post-expired-failed");
            },
            copy_share_URL() {
                // Get url of the post
                let origin = window.location.origin;
                let route  = window.location.pathname;
                let url    = `${origin}${route}`;

                const container = document.querySelector(".v-dialog");
                this.$copyText(url, container).then(() => {
                    this.eventbus.$emit("SB", "dialog-post.url-copied", "success", 5);
                });
            },
            post_marked_as_expired(postid) {
                this.post.marked_as_expired = "1";
                this.$emit("post_not_found", postid);
            },
            post_removed(postid) {
                this.post.disabled = "1";
                this.$emit("post_not_found", postid);
            },
            post_changed() {
                // Reload the post
                this.load_post(this.post.ID);
            },
            set_expiration_date() {
                if (this.post.expiration_date !== null) {
                    let current_year = new Date().getFullYear();
                    let exp          = this.moment(this.post.expiration_date).format("DD.MM");
                    let year         = this.moment(this.post.expiration_date).format("YYYY");
                    let time         = this.moment(this.post.expiration_date).format("HH:mm");

                    exp += parseInt(year) !== current_year ? `.${year}` : "";
                    exp += time !== "00:00" ? ` ~${time}` : "";
                    this.expiration = exp;
                }
            }
        },
        metaInfo() {
            if (this.dialog) { // Only put posttitle in title when dialog is open
                return {
                    title: `${this.$t('meta.page-title-unformated')} - ${this.post.title}`,
                    meta : [{
                        name   : "description",
                        content: this.$t("meta.description"),
                    }]
                }
            }
        },
    }
</script>