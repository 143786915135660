<template>
    <div>
        <dialog-confirm ref="confirm"></dialog-confirm>

        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                        v-if="post.disabled ==='0'"
                        v-bind="attrs"
                        v-on="on"
                        @click="delete_post"
                        color="red">
                    mdi-trash-can-outline
                </v-icon>
            </template>
            <span>{{$t("dialog-post.delete-own-post")}}</span>
        </v-tooltip>
    </div>
</template>

<script>
    export default {
        props  : ["post"],
        data() {
            return {
                dialog: false,
            }
        },
        methods: {
            async delete_post() {
                if (await this.$refs.confirm.open(this.$t("dialog-post.dialog-confirm.delete-post"), this.$t("dialog-post.dialog-confirm.delete-post-are-you-sure"))) {
                    if (this.$user.user.id === this.post.userID || this.$root.check_mod()) {
                        let response = await this.func.ax("/php/post_delete.php", {
                            postid: this.post.ID,
                            userid: this.$user.user.id,
                            token : this.$user.user.token,
                        });
                        await this.$root.check_response(response, () => {
                            this.$emit("post_removed", this.post.ID);
                            this.eventbus.$emit("SB", "dialog-post.msg.post-is-deleted", "success", 5);
                        }, "dialog-post.msg.post-remove-failed");

                        this.dialog = false;
                    }
                }
            },
        }
    }
</script>