<template>
    <div v-if="$user.user.auth && $user.user.roleID !== $variables.v.role_ids.leecher">
        <v-textarea
                v-model="commenttext"
                :color="$variables.v.theme.secondary"
                :placeholder="$t(this.layer === 1 ? 'post.comments.comment-this-post' : 'post.comments.comment-this-comment')"
                :rules="[rules.max1000]"
                counter
                outlined
                auto-grow
                no-resize
                rows="2"
                @click="comment_clicked = true"
                maxlength="1000"></v-textarea>
        <div class="text-right">
            <v-btn text
                   class="mr-2"
                   @click="cancel_comment">
                {{$t("post.comments.cancel")}}
            </v-btn>

            <v-btn :key="unlock_post_comment"
                   depressed
                   :disabled="commenttext.length < 10"
                   :color="$variables.v.theme.primary"
                   @click.once="post_comment">
                {{$t("post.comments.comment")}}
            </v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        props  : ['comment', 'postid', 'layer'],
        data() {
            return {
                commenttext        : "",
                unlock_post_comment: 0,
                comment_clicked    : null,

                rules: {
                    max1000: v => (v !== undefined && v.length <= 1000) || this.$t("rules.max-1000-chars"),
                },

            }
        },
        methods: {
            async post_comment() {
                let commenttext = this.commenttext.trim();
                if (commenttext.length >= 10 && commenttext.length <= 1000) {
                    let response = await this.func.ax("/php/post_comment.php", {
                        func     : "post_comment",
                        userid   : this.$user.user.id,
                        token    : this.$user.user.token,
                        postid   : this.postid,
                        commentid: this.comment ? this.comment.ID : null,
                        comment  : commenttext,
                    });

                    await this.$root.check_response(response, () => {
                        this.commenttext = "";
                        this.$emit("comment_created");
                        this.$emit("comment_reset");
                    }, "support.mailsupport.msg.error");
                }
                this.unlock_post_comment++;
            },
            cancel_comment() {
                this.commenttext     = '';
                this.comment_clicked = false;
                this.$emit("comment_reset");
            },
        }
    }
</script>