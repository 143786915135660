<template>
    <v-dialog v-model="dialog" width="800px">
        <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs"
                    v-on="on"
                    color="red"
                    @click="open_dialog">
                mdi-pencil-outline
            </v-icon>
        </template>

        <loading-circle v-if="loading"></loading-circle>

        <v-form v-if="!loading" v-model="valid" ref="r_form_edit_post" class="post-create-form grey darken-3">
            <dragger></dragger>

            <v-row>
                <v-col cols="12" :sm="!([5, 6].indexOf(post.itemtypeID) >= 0) ? 6 : 12">
                    <span class="disable-select">{{$t("create-post.itemtype")}}</span>
                    <v-select
                            v-model="post.itemtypeID"
                            :items="sorted_itemtypes"
                            item-value="id"
                            :color="$variables.v.theme.secondary"
                            dense
                            single-line
                            outlined>
                        <template slot=selection slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                        <template slot=item slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12" sm="6" v-if="!([5, 6].indexOf(post.itemtypeID) >= 0)">
                    <span class="disable-select">{{$t("create-post.service")}}</span>
                    <v-select
                            v-model="post.serviceID"
                            :items="sorted_services"
                            :color="$variables.v.theme.secondary"
                            item-value="id"
                            dense
                            single-line
                            outlined>
                        <template slot=selection slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                        <template slot=item slot-scope="data">
                            {{$t(data.item.text)}}
                        </template>
                    </v-select>
                </v-col>

                <v-col v-if="post.itemtypeID === 2" cols="12" sm="6">
                    <v-text-field v-model="post.base_game_url"
                                  :label="$t('create-post.base-game-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  counter
                                  dense
                                  maxlength="300"></v-text-field>
                </v-col>
                <v-col v-if="post.itemtypeID === 2" cols="12" sm="6">
                    <v-checkbox class="mr-3"
                                v-model="post.base_game_not_free"
                                :label="$t('create-post.payed-base-game')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>
                <v-col cols="6">
                    <v-checkbox v-model="post.vpn_required"
                                :label="$t('create-post.vpn-required')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>
                <v-col cols="6" v-if="Number(post.offer_type) === $variables.v.offer_types.giveaway">
                    <v-checkbox v-model="post.key_giveaway"
                                :label="$t('create-post.key-giveaway')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>

                <v-col cols="6" v-if="Number(post.offer_type) === $variables.v.offer_types.giveaway">
                    <v-checkbox v-model="unknown_expiration"
                                :label="$t('create-post.unknown-expiration')"
                                :color="$variables.v.theme.secondary"></v-checkbox>
                </v-col>


                <v-col cols="3" v-if="Number(post.offer_type) === $variables.v.offer_types.deal">
                    <v-text-field v-model="post.deal_discount_in_percent"
                                  :label="$t('create-post.price-discount-percent')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                                  append-icon="mdi-percent-outline"
                                  :rules="[rules.percentage]"></v-text-field>
                </v-col>


                <v-col cols="3" v-if="Number(post.offer_type) === $variables.v.offer_types.deal">
                    <v-text-field v-model="post.deal_currency"
                                  :label="$t('create-post.price-currency')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                                  counter
                                  maxlength="7"></v-text-field>
                </v-col>


                <v-col cols="6" v-if="Number(post.offer_type) === $variables.v.offer_types.deal">
                    <v-text-field v-model="post.deal_price_original"
                                  :label="$t('create-post.price-original')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                                  @focusout="calc_discount"
                                  :rules="[rules.required, rules.max7, c_is_number_original]"
                                  maxlength="7"></v-text-field>
                </v-col>
                <v-col cols="6" v-if="Number(post.offer_type) === $variables.v.offer_types.deal">
                    <v-text-field v-model="post.deal_price_reduced"
                                  :label="$t('create-post.price-reduced')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                                  @focusout="calc_discount"
                                  :rules="[rules.required, rules.max7, c_is_number_reduced]"
                                  maxlength="7"></v-text-field>
                </v-col>


                <v-col cols="12">
                    <v-text-field v-model="post.title"
                                  :label="$t('create-post.post-title')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  counter
                                  :rules="[rules.required, rules.min5, rules.max80]"
                                  maxlength="70"></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-textarea v-model="post.description"
                                :label="$t('create-post.post-description')"
                                maxlength="2000"
                                :rules="[rules.max2000]"
                                :color="$variables.v.theme.secondary"
                                counter
                                outlined
                                auto-grow
                                no-resize
                                rows="3"></v-textarea>
                </v-col>


                <v-col cols="12" md="6">
                    <v-text-field v-model="post.offer_url"
                                  :label="$t('create-post.post-offer-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense
                    ></v-text-field>

                    <v-text-field v-model="post.picture_url"
                                  :label="$t('create-post.post-picture-url')"
                                  :color="$variables.v.theme.secondary"
                                  outlined
                                  dense></v-text-field>
                </v-col>


                <v-col cols="12" md="6">
                    <img :src="post.picture_url"
                         style="max-height:130px"
                         class="post-image"
                         alt=""
                         loading="lazy">
                </v-col>


                <v-col cols="12" sm="6" v-if="!unknown_expiration">
                    <v-dialog
                            ref="dialog"
                            v-model="modal_date"
                            :return-value.sync="expiration_date"
                            width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="expiration_date"
                                    :label="$t('create-post.post-expiration-date')"
                                    :color="$variables.v.theme.secondary"
                                    outlined
                                    readonly
                                    clearable
                                    dense
                                    v-bind="attrs"
                                    v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="expiration_date"
                                       :min="current_date"
                                       @change="$refs.r_form_edit_post.validate()"
                                       scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="modal_date = false">{{$t("date-time-modal.cancel")}}
                            </v-btn>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="$refs.dialog.save(expiration_date)">{{$t("date-time-modal.ok")}}
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>

                <v-col cols="12" sm="6" v-if="!unknown_expiration">
                    <v-dialog
                            ref="dialog2"
                            v-model="modal_time"
                            :return-value.sync="expiration_time"
                            width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="expiration_time"
                                    :label="$t('create-post.post-expiration-time')"
                                    :color="$variables.v.theme.secondary"
                                    outlined
                                    readonly
                                    clearable
                                    dense
                                    v-bind="attrs"
                                    v-on="on"
                                    :rules="[c_check_expiration_date]"></v-text-field>
                        </template>
                        <v-time-picker
                                v-if="modal_time"
                                v-model="expiration_time"
                                full-width>
                            <v-spacer></v-spacer>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="modal_time = false">{{$t("date-time-modal.cancel")}}
                            </v-btn>
                            <v-btn text
                                   :color="$variables.v.theme.secondary"
                                   @click="$refs.dialog2.save(expiration_time)">{{$t("date-time-modal.ok")}}
                            </v-btn>
                        </v-time-picker>
                    </v-dialog>
                </v-col>


                <v-col cols="12">
                    <div class="text-center">
                        <v-btn x-large
                               depressed
                               :key="unlock_edit_button"
                               :disabled="!valid"
                               color="success"
                               @click="save_post">
                            {{$t("edit-post.apply-post")}}
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-form>
    </v-dialog>
</template>

<script>
    export default {
        props   : ["postid"],
        data() {
            return {
                dialog            : false,
                valid             : false, // Disable Button when form not valid
                unknown_expiration: false,
                sorted_itemtypes  : [],
                sorted_services   : [],
                loading           : false,
                unlock_edit_button: 0,
                post              : {},

                current_date   : null,
                modal_date     : false,
                modal_time     : false,
                expiration     : "",
                expiration_date: null,
                expiration_time: null,

                rules: {
                    required  : v => (!!v) || this.$t("rules.required"),
                    min5      : v => (v !== undefined && v.length >= 5) || this.$t("rules.min-5-chars"),
                    max7      : v => (v !== undefined && v.length <= 7) || this.$t("rules.max-7-chars"),
                    max80     : v => (v !== undefined && v.length <= 80) || this.$t("rules.max-80-chars"),
                    max2000   : v => (v !== undefined && v.length <= 2000) || this.$t("rules.max-2000-chars"),
                    percentage: v => (v !== undefined && v.length === 0 || (parseInt(v) >= 1 && parseInt(v) <= 99)) || this.$t("rules.percentage"),
                },
            }
        },
        computed: {
            c_is_number_original() {
                return () => !isNaN(this.post.deal_price_original.replace(',', '.')) || this.$t("rules.not-number");
            },
            c_is_number_reduced() {
                return () => !isNaN(this.post.deal_price_reduced.replace(',', '.')) || this.$t("rules.not-number");
            },
            c_check_expiration_date() {
                return () => this.expiration_date !== null || this.expiration_time === null || this.$t("rules.date-required");
            },
        },
        async created() {
            this.func.vuetify_dragable_dialogs();
            this.sorted_itemtypes = this.$variables.v.itemtypes.sort(this.func.compare_by_pos);
            this.sorted_services  = this.$variables.v.services.sort(this.func.compare_by_pos);
        },
        watch   : {
            async dialog() {
                if (!this.dialog)
                    this.post = {};
            },
        },
        methods : {
            async open_dialog() {
                this.loading      = true;
                this.current_date = new Date().toJSON().slice(0, 10).replace(/-/g, '-');

                let response = await this.func.ax("/php/getter.php", {
                    func  : "get_post",
                    userid: this.$user.user.id,
                    token : this.$user.user.token,
                    postid: this.postid,
                });
                if (response) {
                    this.post               = { // Convert all variables to correct type
                        ID                      : Number(response.ID),
                        offer_type              : Number(response.offer_type),
                        serviceID               : Number(response.serviceID),
                        itemtypeID              : Number(response.itemtypeID),
                        base_game_url           : response.base_game_url,
                        base_game_not_free      : Number(response.base_game_not_free),
                        deal_discount_in_percent: response.deal_discount_in_percent ? Number(response.deal_discount_in_percent) : "",
                        deal_currency           : response.deal_currency,
                        deal_price_original     : response.deal_price_original,
                        deal_price_reduced      : response.deal_price_reduced,
                        vpn_required            : Number(response.vpn_required),
                        key_giveaway            : Number(response.key_giveaway),
                        title                   : response.title,
                        description             : response.description,
                        offer_url               : response.offer_url,
                        picture_url             : response.picture_url,
                        points                  : Number(response.points),
                        expiration_date         : response.expiration_date,
                        created_at              : response.created_at,
                        count_expired           : Number(response.count_expired),
                        userID                  : Number(response.userID),
                        disabled                : Number(response.disabled),
                    };
                    this.unknown_expiration = this.post.expiration_date === null;
                    if (this.post.expiration_date !== null) {
                        this.expiration_date = this.moment(this.post.expiration_date).format("YYYY-MM-DD");
                        this.expiration_time = this.moment(this.post.expiration_date).format("HH:mm");
                    }
                }
                else {
                    this.eventbus.$emit("SB", "edit-post.msg.error-loading-post", "error");
                    this.dialog = false;
                }
                this.loading = false;
            },
            calc_discount() {
                this.post.deal_discount_in_percent = String(100 - parseInt(100 / this.post.deal_price_original.replace(',', '.') * this.post.deal_price_reduced.replace(',', '.')));
            },
            build_postdata() {
                let postdata    = {};
                postdata.userid = this.$user.user.id;
                postdata.token  = this.$user.user.token;

                postdata.postid = this.post.ID;

                postdata.serviceid  = !([5, 6].indexOf(this.post.itemtypeID) >= 0) ? this.post.serviceID : null;
                postdata.itemtypeid = this.post.itemtypeID;

                postdata.base_game_url      = this.post.itemtypeID === 2 ? this.func.add_http(this.post.base_game_url.trim()) : "";
                postdata.base_game_not_free = this.post.itemtypeID === 2 ? this.post.base_game_not_free : null;

                let deal                          = Number(this.post.offer_type) === this.$variables.v.offer_types.deal;
                postdata.deal_discount_in_percent = deal ? this.post.deal_discount_in_percent : null;
                postdata.deal_currency            = deal ? this.post.deal_currency : "";
                postdata.deal_price_original      = deal ? this.post.deal_price_original.trim().replace(',', '.') : "";
                postdata.deal_price_reduced       = deal ? this.post.deal_price_reduced.trim().replace(',', '.') : "";

                postdata.vpn_required = this.post.vpn_required;
                postdata.key_giveaway = this.post.key_giveaway;

                postdata.title           = this.post.title;
                postdata.description     = this.post.description;
                postdata.offer_url       = this.post.offer_url;
                postdata.picture_url     = this.post.picture_url;
                postdata.expiration_date = this.unknown_expiration ? null : this.func.get_date_time(this.expiration_date, this.expiration_time);

                return postdata;
            },
            async save_post() {
                if (this.$refs.r_form_edit_post.validate()) {
                    let response = await this.func.ax("/php/post_edit.php", this.build_postdata());
                    await this.$root.check_response(response, () => {
                        this.eventbus.$emit("SB", "edit-post.msg.success", "success", 5);
                        this.$emit("post_changed");
                        this.dialog = false;
                    }, "edit-post.msg.error");
                }
            },
        },
    }
</script>