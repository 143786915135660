<template>
    <div class="commentbox-comment" :class="'commentbox-layer-'+layer">
        <div>
            <miniview-user :user="comment"></miniview-user>

            <div class="comment-options-container">
                <commentbox-comment-remove :comment="comment" class="mr-4" @comment_removed="$emit('comment_removed')"></commentbox-comment-remove>

                <dialog-post-comment-report :comment="comment" class="mr-4"></dialog-post-comment-report>
            </div>
        </div>

        <div class="comment-created-at">
            {{c_created_at}}
        </div>

        <div class="comment-text content-from-textarea">
            <read-more :text="comment.comment"
                       :more-str="$t('read-more.read-more')"
                       :less-str="$t('read-more.read-less')"
                       link="#"
                       :max-chars="100"></read-more>

            <div v-if="$user.user.auth && $user.user.roleID !== $variables.v.role_ids.leecher && layer < $variables.v.post_comments_max_layers">
                <v-btn v-if="hide_reply_textarea"
                       depressed
                       class="comment-reply-btn"
                       @click="hide_reply_textarea = false">
                    <v-icon>mdi-reply</v-icon>
                    {{$t("post.comments.reply")}}
                </v-btn>
                <commentbox-input v-if="!hide_reply_textarea" :postid="postid" :comment="comment" :layer="layer+1" @comment_created="load_more_comments(true)" @comment_reset="hide_reply_textarea = true"></commentbox-input>
            </div>


            <div>
                <div :key="unlock_reply_buttons" v-if="layer <= $variables.v.post_comments_max_layers && comment.reply_amount !== '0'">
                    <div v-if="!hide_button" @click.once="load_more_comments" class="comment-btn-show-hide disable-select">
                        {{$t("post.comments.show-replies1")}} {{comment.reply_amount}} {{$t("post.comments.show-replies2")}}
                    </div>
                    <div v-if="hide_button" @click.once="hide_replies" class="comment-btn-show-hide disable-select">
                        {{$t("post.comments.hide-replies")}}
                    </div>
                </div>
                <loading-circle v-if="loading"></loading-circle>

                <div v-if="show_replies" class="">
                    <div v-for="c in replies" :key="c.ID">
                        <commentbox-comment :layer="layer+1" :comment="c" :postid="postid" @comment_removed="$emit('comment_removed')"></commentbox-comment>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import dialog_post_comment_report from "../../../dialogs/post/dialog-post-comment-report";
    import commentbox_comment_remove from "./commentbox-comment-remove";
    import commentbox_input from "./commentbox-input";


    export default {
        name      : "commentbox-comment",
        components: {
            "dialog-post-comment-report": dialog_post_comment_report,
            "commentbox-comment-remove" : commentbox_comment_remove,
            "commentbox-input"          : commentbox_input,
        },
        props     : ["comment", "postid", "layer", "adminmode"],
        data() {
            return {
                replies                : [],
                unlock_reply_buttons   : 0,
                hide_reply_textarea    : true,
                hide_button            : false,
                loading                : false,
                show_replies           : false,
                first_time_replies_open: true,
            }
        },
        computed  : {
            c_created_at() {
                let current_year = new Date().getFullYear();
                let year         = Number(this.moment(this.comment.created_at).format("YYYY"));
                let format       = current_year === year ? "DD.MM - HH:mm" : "DD.MM.YYYY - HH:mm";
                return this.moment(this.comment.created_at).format(format);
            }
        },
        methods   : {
            async load_more_comments(ignore_first_time = false) {
                if (this.first_time_replies_open || ignore_first_time) {
                    this.loading = true;
                    let response = await this.func.ax("/php/post_comment.php", {
                        func     : "get_comment_replies",
                        postid   : this.postid,
                        commentid: this.comment.ID,
                    });
                    if (this.func.isObject(response)) {
                        this.replies                 = response;
                        this.comment.reply_amount    = response.length;
                        this.hide_button             = true;
                        this.first_time_replies_open = false;
                        this.show_replies            = true;
                    }
                    else
                        this.eventbus.$emit("SB", response, "error");
                    this.loading = false;
                }
                else
                    this.show_replies = true;
                this.unlock_reply_buttons++;
            },
            hide_replies() {
                this.show_replies = false;
                this.hide_button  = false;
                this.unlock_reply_buttons++;
            },
        }
    }
</script>
